/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { setDeepValue as o } from "./object.js";
class n {
  constructor(n, i = {
    ignoreUnknown: !1,
    useNumericKeys: !1
  }) {
    this._jsonToAPI = n, this._options = i, this.apiValues = [], this.jsonValues = [], this._apiToJSON = t(n), this.apiValues = s(this._apiToJSON), this.jsonValues = s(this._jsonToAPI), this.read = o => this.fromJSON(o), this.write = (n, t, s) => {
      const i = this.toJSON(n);
      void 0 !== i && o(s, i, t);
    }, this.write.isJSONMapWriter = !0;
  }
  toJSON(o) {
    if (null == o) return null;
    if (this._apiToJSON.hasOwnProperty(o)) {
      const n = this._apiToJSON[o];
      return this._options.useNumericKeys ? +n : n;
    }
    return this._options.ignoreUnknown ? void 0 : o;
  }
  fromJSON(o) {
    return null != o && this._jsonToAPI.hasOwnProperty(o) ? this._jsonToAPI[o] : this._options.ignoreUnknown ? void 0 : o;
  }
}
function t(o) {
  const n = {};
  for (const t in o) n[o[t]] = t;
  return n;
}
function s(o) {
  const n = [];
  for (const t in o) n.push(t);
  return n.sort(), n;
}
function i() {
  return function (o, t) {
    return new n(o, {
      ignoreUnknown: !0,
      ...t
    });
  };
}
export { n as JSONMap, i as strict };